import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import Abtimg from "../../assets/images/why.png";


function Focus() {
    return (
        <>
            <section className="row_am about_app_section about_page_sectino">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="app_images" data-aos="fade-in" data-aos-duration="1500">
                                <ul>
                                    <li><img className="moving_animation" src={Abtimg} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="about_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Why Would You Choose </span> PKey?</h2>
                                    <p>
                                        With PKey, you gain a partner dedicated to securing your online operations. Our services offer:
                                    </p>
                                </div>
                            </div>
                            <div>
                                <ul data-aos="fade-in" data-aos-duration="1500">
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> Simplified management of digital certificates
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> Reinforced security with HSM integration
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> Integral documents using digital signatures
                                    </li>
                                    <li>
                                        <FontAwesomeIcon icon={faCheck} /> Audit and development of blockchain projects
                                    </li>                                    
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Focus