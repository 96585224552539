import React from 'react'
import { Link } from 'react-router-dom'
import Banner1 from "../../assets/images/banner-shape1.png"
import Banner2 from "../../assets/images/banner-shape2.png"
import Banner3 from "../../assets/images/banner-shape3.png"

const Main = ({ title, tag, bgimg, paragraph }) => {

    return (

        <>
            <div className="bred_crumb video" style={{ /* backgroundImage: `url(${bgimg})` */ }}>
                <div className="container">
                    <span className="banner_shape1"> <img src={Banner1} alt="image" /> </span>
                    <span className="banner_shape2"> <img src={Banner2} alt="image" /> </span>
                    <span className="banner_shape3"> <img src={Banner3} alt="image" /> </span>

                    <div className="bred_text">
                        <h1>{title}</h1>
                        <p>{paragraph}</p>
                        <ul>
                            <li><Link to="/">Home</Link></li>
                            <li><span>»</span></li>
                            <li><Link to="#">{tag}</Link></li>
                        </ul>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Main