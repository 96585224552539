import { Link } from 'react-router-dom'
import React from 'react'
import logo from '../../assets/images/footer-logo.png'
import top from '../../assets/images/go_top.png'
import Banner1 from '../../assets/images/banner-shape1.png'
import Banner2 from '../../assets/images/banner-shape2.png'

const Main = ({ footer }) => {

    return (
        <>
            <section className="newsletter_section">
                <div className="container">
                    <div className="newsletter_box">
                        <div className="section_title" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <h2>Subscribe newsletter</h2>
                            <p>Be the first to recieve all latest post in your inbox</p>
                        </div>
                        <form action="" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                            <div className="form-group">
                                <input type="email" className="form-control" placeholder="Enter your email" />
                            </div>
                            <div className="form-group">
                                <button className="btn">SUBMIT</button>
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            {footer.f5 &&
                <footer>
                    <div className="top_footer footer-wave" id="contact">
                        <div className="container">
                            <span className="banner_shape1"> <img src={Banner1} alt="image" /> </span>
                            <span className="banner_shape2"> <img src={Banner2} alt="image" /> </span>
                            <div className="row">
                                <div className="col-lg-4 col-md-6 col-12">
                                    <div className="abt_side">
                                        <div className="logo"> <img src={logo} alt="image" style={{ width: '75px', height: 'auto' }} /></div>
                                        <ul>
                                            <li><Link to="#">contact@p-key.xyz</Link></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-6 col-12">
                                    <div className="links">
                                        <h3>Useful Links</h3>
                                        <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/#about">About us</Link></li>
                                            <li><Link to="#">Products And Services</Link></li>
                                            <li><Link to="/contact">Contact us</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="bottom_footer">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-6">
                                    <p>© Copyrights 2024. All rights reserved.</p>
                                </div>
                                <div className="col-md-6">
                                    <p className="developer_text">Powered by PKey</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="go_top">
                        <span><img src={top} alt="image" /></span>
                    </div>
                </footer>
            }

        </>
    )
}

export default Main