import React from 'react'
import { Link } from 'react-router-dom'
import Lineimg from "../../../assets/images/anim_line.png";
import Appstore from "../../../assets/images/appstore_blue.png";
import Googleplay from "../../../assets/images/googleplay_blue.png";
import Screenimg from "../../../assets/images/download-screen01.png";
import Screenimg02 from "../../../assets/images/download-screen02.png";

function Second() {
    return (
        <>
            <section className="row_am free_app_section review_freeapp" id="getstarted">
                <div className="container">
                    <div className="free_app_inner aos-init" data-aos="fade-in" data-aos-duration="1500" data-aos-delay="100">
                        <div className="anim_line dark_bg">
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                            <span><img src={Lineimg} alt="anim_line" /></span>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="free_text">
                                    <div className="section_title">
                                        <h2>Let’s download free from apple and play store</h2>
                                        <p>Instant free download from apple and play store orem Ipsum is simply dummy text of the printing.
                                            and typese tting indus orem Ipsum has beenthe standard</p>
                                    </div>
                                    <ul className="app_btn">
                                        <li>
                                            <Link to="#">
                                                <img src={Appstore} alt="image" />
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="#">
                                                <img src={Googleplay} alt="image" />
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="free_img">
                                    <img src={Screenimg} alt="image" />
                                    <img className="mobile_mockup" src={Screenimg02} alt="image" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Second