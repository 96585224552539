import React from 'react'
import Providing from '../Menabout/Providing'
import Focus from '../Menabout/Focus'

const Main = () => {

    return (
        <>
            <Providing />
            <Focus />
        </>

    )
}

export default Main