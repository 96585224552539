import React from 'react'
import Bannerimg from "../../assets/images/banner.png"



function Banner() {
    return (
        <>
            <section className="banner_section home-wave">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12" data-aos="fade-right" data-aos-duration="1500">
                            <div className="banner_text">
                                <h1>Building Trust <span>in a Digital World.</span></h1>
                                <p>Secure your digital operations with our suite of tailored services, including PKIManager, our digital certificates management solution.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12" data-aos="fade-in" data-aos-duration="1500">
                            <div className="banner_image">
                                <img className="moving_animation" src={Bannerimg} alt="image" />
                            </div>
                        </div>
                    </div>

                </div>
                <div className="home-wave-bottom">
                    <svg className="waves" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                        viewBox="0 24 150 28" preserveAspectRatio="none" shapeRendering="auto">
                        <defs>
                            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
                        </defs>
                        <g className="parallax">
                            <use xlinkHref="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
                            <use xlinkHref="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
                            <use xlinkHref="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
                            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#f6f4fe" />
                        </g>
                    </svg>
                </div>
            </section>
        </>
    )
}

export default Banner