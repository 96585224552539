import OwlCarousel from 'react-owl-carousel'
import React from 'react'
import img1 from '../../../assets/images/nearsecure.png'
import img2 from '../../../assets/images/modcod.png'
import img3 from '../../../assets/images/procenne.png'
import img4 from '../../../assets/images/inwi.png'

const logos = [img1, img2, img3, img4, img1, img2, img3, img4];

const Main = () => {
    
  const company_slider = {
    loop:true,
    margin:10,
    nav:false,
    autoplay: true,
    smartSpeed: 1500,
    dots: true, 
    responsive:{
        0:{
            items:2
        },
        600:{
            items:3
        },
        1000:{
            items:3
        }
    }
  }

  return (
    <>
      <section className="row_am trusted_section">
        <div className="container">
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
            <h2>Trusted by <span>50+</span> companies</h2>
            <p>PKey caters to a diverse range of industries, including finance, healthcare, government, and technology. We understand the unique requirements and regulatory landscapes within each industry, allowing us to deliver solutions that meet specific compliance standards and security needs.</p>
          </div>
        </div>
      </section>
      <section className="row_am trusted_section">
        <div className='container'>
          <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
            <h2>Our <span>Partners</span></h2>
          </div>
          <div className="company_logos">
            <OwlCarousel id="company_slider" {...company_slider} className="owl-carousel owl-theme owl-loaded owl-drag">
              {logos.map((logo, index) => (
                <div key={index} className="item">
                  <div className="logo">
                    <img src={logo} alt="image" />
                  </div>
                </div>
              ))}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  )
}

export default Main
