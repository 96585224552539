import React, { useEffect } from 'react'
import AOS from 'aos'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Leave from '../Mencontact/Leave'

const Main = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    
    return (

        <>
            <Bredcrumb no={4} title="Contact Us" paragraph="If you have an query, please get in touch with us, we will revert back quickly." tag="Contact us" bgimg={BGImg2} />

            <Leave />

        </>
    )
}

export default Main