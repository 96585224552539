import { Link } from 'react-router-dom'
import React , { useState} from 'react'
import img1 from '../../../assets/images/standard.png'
import img2 from '../../../assets/images/unlimited.png'
import img3 from '../../../assets/images/premium.png'
import Starded1 from "../../../assets/images/standard-one.png"
import Unlimited1 from "../../../assets/images/unlimited-one.png"
import Premium1 from "../../../assets/images/premium-one.png"

const Main = ({video}) => {
    
    const[tog, setTog] = useState()

  return (
    <>
        <section className="row_am pricing_section" id="pricing">
            <div className="container">
                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="300">
                <h2>Best & simple <span>pricing</span></h2>
                <p>Simple pricing. No hidden fees. Advanced features for your needs.</p>
                </div>
                <div className="toggle_block" data-aos="fade-up" data-aos-duration="1500">
                    <span className={`month ${tog ? 'active' : ''}`}>Monthly</span>
                    <div className="tog_block">
                        <span className={`tog_btn ${tog && "month_active"}`} onClick = {() => setTog(tog === true ? false : true)}></span>
                    </div>
                    <span className="years">Yearly</span>
                    <span className="offer">20% off</span>
                </div>

                <div className={`pricing_pannel monthly_plan ${tog ? '' : 'active'}`}>
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                { video ? <img src={Starded1} alt="image" /> : <img src={img1} alt="image" /> }
                                </div>
                                <div className="pkg_name">
                                <h3>Standard</h3>
                                <span>For the basics</span>
                                </div>
                                <span className="price">$520</span>
                                <ul className="benifits">
                                <li>
                                    <p>Up to 200 Certificates</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pricing_block highlited_block">
                                <div className="icon">
                                { video ? <img src={Unlimited1} alt="image"/> : <img src={img2} alt="image"/> }
                                </div>
                                <div className="pkg_name">
                                <h3>Premium</h3>
                                <span>For the professionals</span>
                                </div>
                                <span className="price">$2600</span>
                                <ul className="benifits">
                                <li>
                                    <p>Up to 2000 Certificates</p>
                                </li>
                                <li>
                                    <p>Customizable</p>
                                </li>
                                <li>
                                    <p>IIS Deployment</p>
                                </li>
                                <li>
                                    <p>Apache Deployment</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                {video ? <img src={Premium1} alt="image" /> : <img src={img3} alt="image" /> }
                                </div>
                                <div className="pkg_name">
                                <h3>Platinum</h3>
                                <span>For businesses</span>
                                </div>
                                <span className="price"></span>
                                <ul className="benifits">
                                <li>
                                    <p>Unlimited Certificates</p>
                                </li>
                                <li>
                                    <p>Customizable</p>
                                </li>
                                <li>
                                    <p>IIS Deployment</p>
                                </li>
                                <li>
                                    <p>Apache Deployment</p>
                                </li>
                                <li>
                                    <p>Digital Signature</p>
                                </li>
                                <li>
                                    <p>24/7 Customer support</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>

                <div className={`pricing_pannel yearly_plan ${tog ? 'active' : ''}`} >
                    <div className="row">
                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                { video ? <img src={Starded1} alt="image" /> : <img src={img1} alt="image" /> }
                                </div>
                                <div className="pkg_name">
                                <h3>Standard</h3>
                                <span>For the basics</span>
                                </div>
                                <span className="price">$5000</span>
                                <ul className="benifits">
                                <li>
                                    <p>Up to 200 Certificates</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing_block highlited_block">
                                <div className="icon">
                                { video ? <img src={Unlimited1} alt="image"/> : <img src={img2}alt="image"/> }
                                </div>
                                <div className="pkg_name">
                                <h3>Premium</h3>
                                <span>For the professionals</span>
                                </div>
                                <span className="price">$25000</span>
                                <ul className="benifits">
                                <li>
                                    <p>Up to 2000 Certificates</p>
                                </li>
                                <li>
                                    <p>Customizable</p>
                                </li>
                                <li>
                                    <p>IIS Deployment</p>
                                </li>
                                <li>
                                    <p>Apache Deployment</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="pricing_block">
                                <div className="icon">
                                {video ? <img src={Premium1} alt="image" /> : <img src={img3} alt="image" /> }
                                </div>
                                <div className="pkg_name">
                                <h3>Platinum</h3>
                                <span>For businesses</span>
                                </div>
                                <span className="price"></span>
                                <ul className="benifits">
                                <li>
                                    <p>Unlimited Certificates</p>
                                </li>
                                <li>
                                    <p>Customizable</p>
                                </li>
                                <li>
                                    <p>IIS Deployment</p>
                                </li>
                                <li>
                                    <p>Apache Deployment</p>
                                </li>
                                <li>
                                    <p>Digital Signature</p>
                                </li>
                                <li>
                                    <p>24/7 Customer support</p>
                                </li>
                                </ul>
                                <Link to="/contact" className="btn white_btn">CONTACT US</Link>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    </>
  )
}

export default Main