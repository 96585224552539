import { Link } from 'react-router-dom'
import React , { useState} from 'react'
import Cert from '../../../assets/images/cert.png'

const Main = ({video}) => {
    
    return (
        <>
            <section className="row_am app_solution_section" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="app_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>Comprehensive </span> Certificate Lifecycle Management Solution</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    PKIManager is a user-friendly, advanced tool for automating and simplifying digital certificate lifecycle management. 
                                    It offers real-time monitoring of your certificate ecosystem and identifies potential vulnerabilities, enhancing your 
                                    security posture. With robust compliance check features, PKIManager helps maintain adherence to key industry standards 
                                    like PCI-DSS and SWIFT. Designed for integration with your existing infrastructure, this versatile tool supports a wide 
                                    range of environments and can be customized to meet specific needs. Whether you're a small business or a large enterprise, 
                                    PKIManager is a scalable solution that evolves with your organization and the dynamic digital landscape.
                                </p>
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h5><span>Trust PKIManager to streamline your certificate management process, fortify your security, and uphold digital trust. </span></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="app_images" data-aos="fade-in" data-aos-duration="1500">
                                <ul>
                                    <li><img className="moving_animation" src={Cert} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Main