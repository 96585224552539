
import React, { useEffect } from 'react'
import AOS from "aos";
import AboutUs from '../AboutUs/Main'
import Trusted from '../HomeMain/Trusted/Main'
import Banner from '../Menhomewave/Banner';

const Main = ({ setnavbar, setfooter, setbrdcum }) => {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  useEffect(() => {
    setfooter({ f5: true })
    setbrdcum({ b1: true })
    setnavbar({ n4: true })
    localStorage.setItem("navbar", "homewave")

  }, [])

  return (
    <>
      <Banner />
      <AboutUs />
      <Trusted />
    </>
  )
}

export default Main