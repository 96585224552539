import React, { useEffect } from 'react'
import AOS from 'aos'
import Pricing from '../HomeMain/Pricing/Main'
import Bredcrumb from '../Bredcrumb/Main'
import BGImg2 from "../../assets/images/bread_crumb_bg_two.png"
import Top from '../Pages/Menpricing/Top'
import First from '../Pages/Menpricing/First'
import PKIManager from '../Pages/PKIManager/Main'


const Main = () => {

    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);

    return (
        <>
            <Bredcrumb sno={4} title="PKIManager" tag="PKIManager" bgimg={BGImg2} />
            <PKIManager />
            <Pricing />

        </>
    )
}

export default Main