import React, { useState } from 'react'
import Abtimg1 from "../../assets/images/about.png"

function Providing() {

    return (
        <>
            <section className="row_am app_solution_section" id="about">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="app_text">
                                <div className="section_title" data-aos="fade-up" data-aos-duration="1500" data-aos-delay="100">
                                    <h2><span>About </span> PKey</h2>
                                </div>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                    PKey is an innovative startup specializing in cutting-edge digital trust solutions. We are dedicated to 
                                    empowering organizations with secure and reliable technologies that establish and maintain trust in their 
                                    digital transactions and communications. With a focus on areas such as Public Key Infrastructure (PKI), 
                                    digital signatures, encryption, and certificate lifecycle management, PKey offers comprehensive solutions 
                                    and expert consultancy services to address the unique challenges faced by our clients.
                                </p>
                                <p data-aos="fade-up" data-aos-duration="1500">
                                Our mission at PKey is to revolutionize the way organizations approach digital trust. We strive to provide 
                                advanced technologies and services that enable businesses to navigate the digital landscape securely, enhance 
                                their cybersecurity posture, and build customer confidence. We are committed to helping our clients unlock the 
                                full potential of the digital world while maintaining the highest standards of trust and security.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="app_images" data-aos="fade-in" data-aos-duration="1500">
                                <ul>
                                    <li><img className="moving_animation" src={Abtimg1} alt="" /></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Providing