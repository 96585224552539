import React from 'react'
import { Link } from 'react-router-dom'
import Mailicon from "../../assets/images/mail_icon.png"

function Leave() {
    return (
        <>
            <section className="contact_page_section mb-5" id="contact">
                <div className="container">
                    <div className="contact_inner">
                        <div className="contact_form">
                            <div className="contact_title">
                                <h2>Leave a <span>message</span></h2>
                                <p>Fill up form below, our team will get back soon</p>
                            </div>
                            <form action="">
                                <div className="form-group">
                                    <input type="text" placeholder="Name" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <input type="email" placeholder="Email" className="form-control" />
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" placeholder="Your message"></textarea>
                                </div>
                                <div className="form-group mb-0">
                                    <button type="submit" className="btn puprple_btn">SEND MESSAGE</button>
                                </div>
                            </form>
                        </div>
                        <div className="contact_info">
                            <div className="section_title mb-5">
                                <h2>Have any <span>question?</span></h2>
                            </div>
                            <ul className="contact_info_list">
                                <li>
                                    <div className="img">
                                        <img src={Mailicon} alt="image" />
                                    </div>
                                    <div className="text">
                                        <span>Email Us</span>
                                        <Link to="#">contact@p-key.xyz</Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Leave