import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { HashLink as Link } from 'react-router-hash-link';
import logo from "../../assets/images/footer-logo.png";

const Main = ({ navbar }) => {

    const location = useLocation()
    const path = location.pathname

    const [show, setShow] = useState()
    const [mobile, setmobile] = useState()

    useEffect(() => {
        window.scroll(0, 0)
    }, [path]);

    const [menu, setmenu] = useState(false);
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            if (scrollTop > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <>
            <header className={`header ${scrolled ? 'white_header fix_style fixed header-wave' : ''}`}>
                <div className="container">
                    <nav className="navbar navbar-expand-lg">
                        <Link to="/" className="navbar-brand">
                            <img src={logo} alt="image" style={{ width: '75px', height: 'auto' }} />
                        </Link>
                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                            aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <div className={`toggle-wrap ${mobile && "active"}`} onClick={() => setmobile(mobile === true ? false : true)}>
                                <span className="toggle-bar"></span>
                            </div>
                        </button>
                        <div className="collapse navbar-collapse" >
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item" >
                                    <Link to="/" className="nav-link" >Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/#about" className="nav-link">About Us</Link>
                                </li>
                                <li className="nav-item has_dropdown" >
                                    <Link to="#" className="nav-link">Products and Services</Link>
                                    <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                                    <div className="sub_menu" >
                                        <ul>
                                            <li><Link to="/pkimanager">PKIManager</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pkimanager#pricing" className="nav-link">Pricing</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link" >Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link dark_btn">GET STARTED</Link>
                                </li>
                            </ul>
                        </div>
                        <div className={`collapse navbar-collapse mobile-screen ${mobile && "show"}`}  >
                            <ul className="navbar-nav ml-auto">
                                <li className="nav-item has_dropdown" onClick={() => setShow(show === true ? false : true)}>
                                    <Link to="/" className="nav-link" >Home</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/#about" className="nav-link">About Us</Link>
                                </li>
                                <li className="nav-item has_dropdown" >
                                    <Link to="#" className="nav-link">Products and Services</Link>
                                    <span className="drp_btn"><i className="icofont-rounded-down"></i></span>
                                    <div className="sub_menu" >
                                        <ul>
                                            <li><Link to="/pkimanager">PKIManager</Link></li>
                                        </ul>
                                    </div>
                                </li>
                                <li className="nav-item">
                                    <Link to="/pkimanager#pricing" className="nav-link">Pricing</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link" >Contact</Link>
                                </li>
                                <li className="nav-item">
                                    <Link to="/contact" className="nav-link dark_btn">GET STARTED</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>
            </header>

        </>
    )
}

export default Main